import {useState} from 'react';
import { Formik, Form, Field, ErrorMessage as FormikErrorMessage } from 'formik';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';

import useMarvelService from '../../services/MarvelService';
import ErrorMessage from '../errorMessage/ErrorMessage';

import './charSearchForm.scss';

// Компонент відповідає за форму пошуку персонажа за іменем на сторінці.
const CharSearchForm = () => {
  // стан, який зберігає отримані дані про персонажа
  const [char, setChar] = useState(null);
  const {loading, error, getCharacterByName, clearError} = useMarvelService();

  const onCharLoaded = (char) => { setChar(char); }

  // функція оновлює стан char залежно від введеного імені персонажа
  const updateChar = (name) => {
    clearError();
    getCharacterByName(name)
        .then(onCharLoaded);
    }

  const errorMessage = error ? <div className="char__search-critical-error"><ErrorMessage /></div> : null;
  const results = !char ? null : char.length > 0 ?
    <div className="char__search-wrapper">
      <div className="char__search-success">There is! Visit {char[0].name} page?</div>
      <Link to={`/characters/${char[0].id}`} className="button button__secondary">
        <div className="inner">To page</div>
      </Link>
    </div> : 
    <div className="char__search-error">
      The character was not found. Check the name and try again
    </div>;

  return (
    // цей контейнер обгортає всю розмітку компонента
    <div className="char__search-form">
      <Formik  // надає можливість управління формою, зберігання стану та валідацію
        initialValues = {{ // визначає початкові значення полів форми
            charName: '' // встановлено початкове значення як пустий рядок
        }}
        validationSchema = {Yup.object({ // визначає схему валідації за допомогою бібліотеки Yup
            charName: Yup.string().required('This field is required')
        })}
        onSubmit = { ({charName}) => { // визначає функцію, яка виконується при поданні форми
            updateChar(charName); // викликається функція із введеним іменем
        }}
      >
        <Form>
            <label className="char__search-label" htmlFor="charName">Or find a character by name:</label>
            <div className="char__search-wrapper"
              // контейнер, який обгортає поле введення та кнопку пошуку
            > 
                <Field  // забезпечує поле введення форми
                    id="charName" name='charName' 
                    type='text' placeholder="Enter name"/>
                <button  // відображає кнопку для відправлення форми
                    type='submit' className="button button__main"
                    disabled={loading}  // вказує, чи є завантаження в процесі
                >
                  <div className="inner"  // відображає текст всередині кнопки
                  >find</div>
                </button>
            </div>
            <FormikErrorMessage  // відображає повідомлення про помилку, якщо поле валідації не пройшло
            component="div" className="char__search-error" name="charName" />
        </Form>
      </Formik>
            {results}
            {errorMessage}
    </div>
  )
}

export default CharSearchForm;